import React from "react"
import { Container, Box, Grid, Typography } from "@material-ui/core";

import { makeStyles } from '@material-ui/core/styles';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import DescriptionIcon from '@material-ui/icons/Description';

import Layout from "../components/layout"
import SEO from "../components/seo"
import { PageTitle } from '../components/PageTitle'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

export default function NAEPage(props) {
  const classes = useStyles();


  return (
    <Layout>
      <SEO title="Requisições" />
      <Container style={{ marginTop: 50 }}>
        <PageTitle>Núcleo de apoio ao estudante</PageTitle>
        <Grid container style={{ marginTop: 70 }}>
          <Grid sm={6}>
            <p style={{ marginTop: '-20px' }}>Contato: <a title="Entre em contato" style={{ color: '#0000EE' }} href="mailto:nae@facjardins.com.br">nae@facjardins.com.br</a></p>
          </Grid>
        </Grid>
      </Container>
    </Layout>
  )
}
